.chat-polos-theme {

  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: hsl(0, 0%, 100%) !important;
    border-color: #00B1FF !important;
    color: #00B1FF !important;
  }

  .p-tabview .p-tabview-nav .p-tabview-ink-bar {
    z-index: 1;
    display: block;
    position: absolute;
    bottom: 0;
    height: 2px;
    background-color: #00B1FF;
    transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
  }

  .p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
    border-color: #44c3ca;
  }

  .form-check-input:checked {
    background-color: #00B1FF;
    border-color: #00B1FF;
  }

  .p-button {
    color: #ffffff;
    background: #00B1FF;
    border: 1px solid #00B1FF;
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 6px;
  }

  .btn-primary {
    color: white !important;
    background-color: #00B1FF !important;
    border-color: #00B1FF !important;

  }

  .btn-success {
    color: white !important;
    background-color: #00BE6E !important;
    border-color: #00BE6E !important;

  }



  .btn-danger {
    color: white !important;
    background-color: red !important;
    border-color: red !important;
  }

  .btn-secondary {
    color: white !important;
    background-color: #44c3ca !important;
    border-color: #46C8C8 !important;
  }

  .btn-gray {
    color: white !important;
    background-color: #9f9f9f !important;
    border-color: #9f9f9f !important;

  }


  .p-button-warning {
    min-width: 40px;
    max-width: 325px;
    height: 40px;
    background: #ED6F20 !important;
    border-radius: 6px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;

    color: #FFFFFF !important;
    border: 1px solid #ED6F20 !important;
  }

  .p-button-warning:disabled {
    background-color: #ed6f20d1 !important;
    border: 1px solid #ed6f20d1 !important;
    opacity: 1;
  }


  .p-button-primary {
    min-width: 40px;
    max-width: 325px;
    height: 40px;
    background: #00B1FF !important;
    border-radius: 6px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF !important;
    border: 1px solid #07B5E4 !important;

  }

  .p-button-primary:disabled {
    background: #00b3ff9c !important;
    border: 1px solid #00b3ff9c !important;
    opacity: 1;
  }



  .p-button-secondary {
    min-width: 40px;
    max-width: 325px;
    height: 40px;
    background: #46C8C8 !important;
    border-radius: 6px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF !important;
    border: 1px solid #07B5E4 !important;

  }

  .p-button-secondary:disabled {
    background: #46c8c887 !important;
    border: 1px solid #46c8c887 !important;
    opacity: 1;
  }


  .p-button-danger {
    min-width: 40px;
    max-width: 325px;
    height: 40px;
    background: #FD5B5B !important;
    border-radius: 6px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;

    color: #FFFFFF !important;
    border: 1px solid #FD5B5B !important;

  }

  .p-button-success {
    min-width: 40px;
    max-width: 325px;
    height: 40px;
    background: #00BE6E !important;
    border-radius: 6px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;

    color: #FFFFFF !important;
    border: 1px solid #09D28C !important;
  }

  .p-button-success:disabled {
    background-color: #09d28c8c !important;
    border: 1px solid #09d28c8c !important;
    opacity: 1;
  }


  .p-fluid .p-inputgroup .p-button.p-button-icon-only {
    background: #5598B2 !important;
  }

  .p-toast-message-primary {
    background-color: #00B1FF !important;
    color: black !important;
    border: 1px solid #AEC0C6 !important;
  }



  .p-toast-message-secondary {
    background-color: #46c8c8 !important;
    color: black !important;
    border: 1px solid #AEC0C6 !important;
  }

  .p-toast-message-success {
    background-color: #00BE6E !important;
    color: black !important;
    border: 1px solid #AEC0C6 !important;
  }

  .p-toast-message-error {
    background-color: #ffd0d0 !important;
    color: black !important;
    border: 1px solid #AEC0C6 !important;
  }

  .p-toast-message-danger {
    background-color: #ffd0d0 !important;
    color: black !important;
    border: 1px solid #AEC0C6 !important;
  }

  .p-toast-message-warning {
    background-color: #fff3c1 !important;
    color: black !important;
    border: 1px solid #AEC0C6 !important;
  }

  .p-toast-message-info {
    background-color: #dff6fe !important;
    color: black !important;
    border: 1px solid #AEC0C6 !important;
  }

  .p-message-primary {
    background-color: #00B1FF !important;
    color: black !important;
    border: 1px solid #AEC0C6 !important;
  }



  .p-message-secondary {
    background-color: #46C8C8 !important;
    color: black !important;
    border: 1px solid #AEC0C6 !important;
  }

  .p-message-success {
    background-color: #00BE6E !important;
    color: black !important;
    border: 1px solid #AEC0C6 !important;
  }

  .p-message-error {
    background-color: #ffd0d0 !important;
    color: black !important;
    border: 1px solid #AEC0C6 !important;
  }

  .p-message-danger {
    background-color: #ffd0d0 !important;
    color: black !important;
    border: 1px solid #AEC0C6 !important;
  }

  .p-message-warning {
    background-color: #fff3c1 !important;
    color: black !important;
    border: 1px solid #AEC0C6 !important;
  }

  .p-message-info {
    background-color: #dff6fe !important;
    color: black !important;
    border: 1px solid #AEC0C6 !important;
  }

  .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    display: inline-block;
  }

  .side-menu-nav {

    .nav-item {
      margin: 7px 0;

      @media (max-width: 991.98px) {
        flex-basis: 0;
        flex-grow: 1;
        margin: 5px 0;
      }

      .nav-link {
        text-align: center;
        font-size: 24px;
        color: gray;
        width: 56px;
        height: 56px;
        line-height: 56px;
        margin: 0px auto;
        border-radius: 8px;
        padding: 0;


        @media (max-width: 991.98px) {
          font-size: 20px;
          width: 48px;
          height: 48px;
          line-height: 48px;
        }

        &.active {
          background-color: #46c8c82b;
          color: #00afff;
        }
      }

      &.show>.nav-link {
        background-color: white;
        color: #00afff;
      }
    }

    .profile-user {
      height: 36px;
      width: 36px;
      padding: 3px;
    }
  }

}

/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';
@import "../node_modules/@fullcalendar/core/main.min.css";
@import "../node_modules/@fullcalendar/daygrid/main.min.css";
@import "../node_modules/@fullcalendar/timegrid/main.min.css";
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";
@import "styles/chat-polos-theme.scss";
.form-control {
  // background-color: #1c2127 !important;
}


// :host ::ng-deep {
//     .p-dropdown {
//         font-size: 0.875rem;
//         padding: 0.65625rem 0.65625rem;
//     }

//     .p-multiselect {
//         font-size: 0.875rem;
//         padding: 0.65625rem 0.65625rem;
//     }

//     .nb-theme-default table {
//         background-color: white;
//     }
// }


.p-inputtext-sm .p-inputtext {
  font-size: 12px !important;
  padding: 0.6rem 0.6rem !important;
}

.p-inputtext.p-inputtext-sm {
  font-size: 12px !important;
  padding: 0.6rem 0.6rem !important;
}


.p-multiselect .p-multiselect-label {
  padding: 0.6rem 0.6rem !important;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s !important;
  font-size: 12px !important;
  width: 100% !important;
}


// :host ::ng-deep .p-multiselect {
//   min-width: 15rem !important;
//   width: 100% !important;
// }

.p-dropdown .p-dropdown-panel {
  min-width: 100% !important;
  font-size: 12px !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #3B82F6 !important;
  background: #EFF6FF !important;
  font-size: 12px !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  margin: 0;
  padding: 0.75rem 1.25rem;
  border: 0 none;
  color: #495057;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
  font-size: 12px;
}

.p-datatable-sm {
  font-size: 12px !important;
}

.p-dropdown .p-dropdown-label {
  background: transparent;
  border: 0 none;
  font-size: 12px !important;
}

perfect-scrollbar {
  display: block;
  height: 100% !important;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}



.container-bkchat {
  // zoom: 90%;
}


.container-div {
  height: calc(100vh - 150px);
  overflow: auto;
}

.th-codigo {
  max-width: 100px;
  text-align: center;
  white-space: nowrap;
}
